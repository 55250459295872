import httpClient from "../httpClient/httpClient";

const LocationFeatureSettingsService = {
  getLocationFeatureSettings(locationID) {
    return httpClient.get("api/get-location-feature-settings", { params: { locationID } });
  },
  addReportedLocationFeatureSettings(locationID) {
    return httpClient.get("api/add-reported-location-feature-settings", { params: { locationID } });
  },
  deleteLocationFeatureSettings(locationID, featureID) {
    return httpClient.post("api/delete-location-feature-setting", { locationID, featureID });
  },
  saveLocationFeatureSetting(
    locationID,
    featureID,
    clinicWideUserLimit,
    locationWideUserLimit,
    activationDate,
    deactivationDate,
    referenceData,
  ) {
    return httpClient.post("api/save-location-feature-setting", {
      locationID,
      featureID,
      clinicWideUserLimit,
      locationWideUserLimit,
      activationDate,
      deactivationDate,
      referenceData,
    });
  },
  autocompleteLocationFeatureSettings() {
    return httpClient.get("api/autocomplete-location-feature-settings");
  }
};

export default LocationFeatureSettingsService;
