<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner
      v-if="systemSettingsDataLoading"
      :spinnerText="getSystemSettingsDataLoadingSpinnerText"
    />
    <template v-else>
      <mex-heading content="System Settings" />
      <v-row>
        <v-col cols="6">
          <mex-card cardClass="foreground" icon="mdi-api" title="REST Communication Settings">
            <v-row justify="start">
              <v-col align-self="center" cols="4">
                <v-text-field
                  v-model="systemSettingsData.REST_requestValidityPeriod"
                  :filled="!editMode"
                  :readonly="!editMode"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="Request validity period"
                  outlined
                />
              </v-col>
              <v-col align-self="center" cols="2">
                <mex-p class="mb-0" content="second(s)" fontSize="caption" />
              </v-col>
              <v-col align-self="center" cols="6">
                <mex-p
                  class="mb-0"
                  content="The time period in which a request of the REST API is considered valid If this time period is exceeded, the
                authentication fails."
                />
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col align-self="center" cols="4">
                <v-text-field
                  v-model="systemSettingsData.REST_apiKeyLength"
                  :filled="!editMode"
                  :readonly="!editMode"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="API key length"
                  outlined
                />
              </v-col>
              <v-col align-self="center" cols="2">
                <mex-p class="mb-0" content="byte(s)" fontSize="caption" />
              </v-col>
              <v-col align-self="center" cols="6">
                <mex-p
                  class="mb-0"
                  content="The character length of the API key that clinics use to authenticate via the REST API."
                />
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col align-self="center" cols="6">
                <v-text-field
                  v-model="systemSettingsData.REST_rfc4122UuidType"
                  :filled="!editMode"
                  :readonly="!editMode"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="API key type"
                  outlined
                />
              </v-col>
              <v-col align-self="center" cols="6">
                <mex-p
                  class="mb-0"
                  content="The RFC 4122 type used as the basis for evaluating the API key verification."
                />
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col align-self="center" cols="6">
                <v-text-field
                  v-model="systemSettingsData.REST_cryptoSeperator"
                  color="primaryAccent"
                  dense
                  filled
                  hide-details
                  label="IV Separator"
                  outlined
                />
              </v-col>
              <v-col align-self="center" cols="6">
                <mex-p
                  class="mb-0"
                  content="Separator used to visually separate IV and encrypted section from each other."
                />
              </v-col>
            </v-row>
            <v-row v-if="editMode" justify="center">
              <v-col cols="10">
                <mex-sheet color="warning" rounded>
                  <mex-p
                    alignment="center"
                    class="mb-0"
                    content="Be aware that changes to these communication settings can directly affect the functionality of the MedITEX License
                  Server."
                    fontSize="overline"
                    fontWeight="bold"
                  />
                </mex-sheet>
              </v-col>
            </v-row>
          </mex-card>
        </v-col>
        <v-col cols="6">
          <mex-card cardClass="foreground" icon="mdi-server" title="License Server Settings">
            <v-row justify="start">
              <v-col align-self="center" cols="4">
                <v-text-field
                  v-model="systemSettingsData.SYS_periodToDeactivation"
                  :filled="!editMode"
                  :readonly="!editMode"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="Default period to add to deactivation date"
                  outlined
                />
              </v-col>
              <v-col align-self="center" cols="2">
                <mex-p class="mb-0" content="month(s)" fontSize="caption" />
              </v-col>
              <v-col align-self="center" cols="6">
                <mex-p
                  class="mb-0"
                  content="Number of months added to the deactivation date when a clinic is successfully reported. This default value is used as long as no specific number is set for the clinic."
                />
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col align-self="center" cols="4">
                <v-text-field
                  v-model="systemSettingsData.SYS_sessionIdleLimit"
                  :filled="!editMode"
                  :readonly="!editMode"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="Session idle time limit"
                  outlined
                />
              </v-col>
              <v-col align-self="center" cols="2">
                <mex-p class="mb-0" content="min(s)" fontSize="caption" />
              </v-col>
              <v-col align-self="center" cols="6">
                <mex-p
                  class="mb-0"
                  content="Number of minutes after which the current session is locked if no user interaction took place and can only be unlocked again by authentication."
                />
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col align-self="center" cols="6">
                <v-switch
                  v-model="systemSettingsData.SYS_twoFakActive"
                  :label="`Two-factor authentication for system users ${systemSettingsData.SYS_twoFakActive ? 'enabled' : 'disabled'}`"
                  :readonly="!editMode"
                />
              </v-col>
            </v-row>
          </mex-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <mex-card cardClass="foreground" icon="mdi-clock-fast" title="Shortcuts">
            <v-row justify="center">
              <v-col align-self="center" cols="12">
                <mex-p
                  alignement="center"
                  content="Please be aware that the use of these shortcuts will irrevocably affect large amounts of data!"
                  fontSize="h6"
                  fontWeight="bold"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <mex-btn content="Location Feature Autocomplete" @click="locationFeaturesAutocompletePreAuthentication"/>
              </v-col>
              <v-col cols="6">
                <mex-p
                  class="mb-0"
                  content="Uses the latest reported active Location-Features as foundation for the Location-Feature-Settings. Existing Location-Feature-Settings will be overwritten!"
                />
              </v-col>
            </v-row>
          </mex-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn v-if="writeSettings && !editMode" content="Edit" @click="editMode = true" />
          <mex-btn v-if="editMode" content="Save Changes" @click="saveChangesPreAuthentication" />
        </v-col>
        <v-col cols="auto">
          <mex-btn v-if="editMode" content="Cancel" @click="fetchSystemSettingsData" />
        </v-col>
      </v-row>
      <save-request
        :showSaveRequest="showSaveRequest"
        @closeSaveRequest="showSaveRequest = false"
      />
      <pre-operation-authenticator-overlay
        v-if="showPreSaveAuthentificator"
        operationDescription="save the system settings changes"
        @abort="showPreSaveAuthentificator = false"
        @successful-authentication="saveChanges"
      />
      <pre-operation-authenticator-overlay
        v-if="showPreAutocompleteAuthenticator"
        operationDescription="use the Location-Features autocomplete"
        @abort="showPreAutocompleteAuthenticator = false"
        @successful-authentication="locationFeaturesAutocomplete"
      />
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PreOperationAuthenticatorOverlay
  from "../../components/LicSrvComponents/PreOperationAuthenticatorOverlay.vue";
import SystemSettingsService from "../../services/systemSettings.service";
import SaveRequest from "../../components/LicSrvComponents/SaveRequest.vue";
import requiredPermissions from "../../requiredPermissions";
import LocationFeatureSettingsService from "../../services/locationFeatureSettings.service";

export default {
  name: "SystemSettings",
  components: { SaveRequest, PreOperationAuthenticatorOverlay },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getSystemSettingsDataLoadingSpinnerText() {
      return this.systemSettingsDataLoadingText;
    },
    writeSettings() {
      return this.getUserPermissions.includes("write_System_System Settings");
    }
  },
  data() {
    return {
      // Visualization
      systemSettingsDataLoading: true,
      systemSettingsDataLoadingText: "",
      editMode: false,
      showPreSaveAuthentificator: false,
      showPreAutocompleteAuthenticator: false,
      // systemSettings data
      systemSettingsData: null,
      showSaveRequest: false,
      hasPermission: false
    };
  },
  methods: {
    fetchSystemSettingsData() {
      this.editMode = false;
      this.systemSettingsDataLoading = true;
      this.systemSettingsDataLoadingText = "Loading System Settings";
      // fetch system user data
      SystemSettingsService.getSystemSettings()
        .then((response) => {
          this.systemSettingsData = response.data;
          this.systemSettingsDataLoading = false;
        })
        .catch((err) => {
          this.$toast.error(err);
          this.systemSettingsDataLoading = false;
        });
    },
    locationFeaturesAutocomplete() {
      this.systemSettingsDataLoading = true;
      this.showPreAutocompleteAuthenticator = false;
      this.systemSettingsDataLoadingText = "Autocompleting Location Feature Settings"
      LocationFeatureSettingsService.autocompleteLocationFeatureSettings()
        .then(() => {
          this.systemSettingsDataLoading = false;
        })
        .catch(err => {
          this.$toast.error(err);
          this.systemSettingsDataLoading = false;
        })
    },
    locationFeaturesAutocompletePreAuthentication() {
      this.showPreAutocompleteAuthenticator = true;
    },
    saveChangesPreAuthentication() {
      this.showPreSaveAuthentificator = true;
    },
    saveChanges() {
      SystemSettingsService.updateSystemSettings(this.systemSettingsData)
        .then(() => {
          this.$toast.success("System settings successfully updated");
          this.fetchSystemSettingsData();
          this.editMode = false;
          this.showPreSaveAuthentificator = false;
        })
        .catch((err) => {
          this.$toast.error(err);
          this.fetchSystemSettingsData();
          this.editMode = false;
          this.showPreSaveAuthentificator = false;
        });
    }
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.systemSettings, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchSystemSettingsData();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  }
};
</script>

<style scoped></style>
